import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AudioPlayer from 'material-ui-audio-player';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

// @material-ui/icons
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import HighQualityIcon from '@material-ui/icons/HighQuality';
import RssFeedIcon from '@material-ui/icons/RssFeed';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

const muiTheme = createMuiTheme({});
const src = [
  'https://streams.lauschr.io/8fe615ae-1f23-473f-a644-f9ea8456bc4a',
];

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section} ID="concept">
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Vorleser-KI mit bester Qualität</h2>
          <h5 className={classes.description}>
            Nicht immer wenn man will, hat man Zeit zu lesen. Manchmal muss man sich durch den Feierabendverkehr quälen, die Garage aufräumen oder einfach von A nach B gehen.
            Und nicht immer, wenn man Zeit zum Lesen hat, hat man auch Lust zu lesen. Wenn man schon die ganze Woche auf einen Bildschirm hat starren müssen, ist einem vielleicht eher
            nach einem Spaziergang, etwas Sport oder einfach nach aus dem Fenster gucken. Aber man hat vielleicht Lust, die neuste Reportage zu lesen. Oder weiß, dass man es sollte.
            <br/>
            Lauschr ermöglicht es allen, die Texte produzieren, ein neues Medium zu erobern: unkompliziert, günstig und auf vielen Kanälen. Die Ohren sind frei. Nutze sie!
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Einbettung & Multimodalität"
              description="Die erstellten Audiodateien lassen sich  direkt auf der eigenen Website oder App einbetten. So fügen Sie Ihrem bestehenden Angebot
              ganz einfach eine neue Art des Konsumierens Ihrer Inhalte zur Verfügung. Erobern Sie mehr Zeit für Ihre Inhalte und gewinnen Sie neue Zielgruppen oder alte zurück."
              icon={PlayCircleOutlineIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Podcastification & neue Kanäle erobern (bald)"
              description="Audioinhalte lassen sich aber nicht nur im Rahmen Ihrer bestehenden Infrastruktur einbinden. Sie können Ihre Inhalte auch in weitere Platformen einspeisen. Bündeln Sie Inhalte, beispielsweise Leitartikel, Kolumnen oder alle Wirtschaftsartikel, in einem automatisiert erstellten Podcast. Erobern Sie neue Kanäle und vergrößern Sie Ihre Reichweite!"
              icon={RssFeedIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Hohe Qualität dank State-of-the-Art KI"
              description="Dank KI-basierter Sprachsynthese klingen die von uns vertonten Text fast als hätte ein echter Mensch sie eingesprochen. Dafür nutzen wir modernste Cloud-Systeme,  basierend auf künstlicher Intelligenz. Entdecken Sie verschiedene Stimmen und Stimmungen."
              icon={HighQualityIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>

      </div>
      <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Hören Sie die Qualität</h2>
          <ThemeProvider theme={muiTheme}>
              <AudioPlayer
                elevation={1}
                width="100%"
                variation="default"
                spacing={3}
                download={false}
                autoplay={false}
                order="standart"
                preload="auto"
                loop={false}
                src={src}
              />
          </ThemeProvider>
          </GridItem>
        </GridContainer>
    </div>
  );
}

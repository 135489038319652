import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Nehmen Sie Kontakt auf!</h2>
          <h4 className={classes.description}>
            Lauschr ist noch im Beta-Status. Wir entwickeln uns stetig weiter und suchen Partner.
            Sie wollen Lauschr einsetzen? Sie haben Fragen zu Preisen? Oder wollen uns einfach kennenlernen?
            Wir freuen uns schon jetzt auf Ihre Nachricht und melden uns schon bald!
            <a href="mailto:hallo@lauschr.de">E-Mail: hallo@lauschr.de</a>
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import CarouselSection from "./Sections/CarouselSection.js";


import Typed from "react-typed";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
        <Header
          color="blacktransparent"
          routes={dashboardRoutes}
          brand={<img width="133" src={require("assets/img/lauschr_logo.png")}/>}
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
        {...rest}
        />
        <Parallax filter image={require("assets/img/c-d-x-PDX_a_82obo-unsplash.jpg")} ID="top">
          <div className={classes.container} >
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <h1 className={classes.title}>Den Lesern was auf die Lauschr geben.</h1>
                <h4>   <b>               
          <Typed
            strings={["Journalistische Inhalte", "Wissenschaftliche Texte", "Fachartikel"]}
            typeSpeed={30}
            backSpeed={40}
            loop
          /> </b> sind wichtig und liegen doch oft nur als Textform vor. Das schränkt Kann KI das nicht erledigen? Dank Lauschr kann sie es. 
                  Und zwar wo auch immer Sie wollen. Das eröffnet Freiheit und erobert Zeit, vergrößert ganz nebenbei die Reichweite Ihrer Inhalte, und erlaubt auch jenen hochqualitativen
                  Zugang zu Ihren Inhalten, für die Lesen zunehmend anstrengend oder gar keine Option ist.
                </h4>
                {/*<br />
                <Button
                  color="danger"
                  size="lg"
                  href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPlay/>
                  Watch video
                </Button>*/}
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ProductSection />
            <CarouselSection />   

            <TeamSection />
            <WorkSection />
          </div>
        </div>
        <Footer />
      </div>
  );
}

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/impressum.js";


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Impressum(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
        <Header
          color="white"
          brand={<img width="133" src={require("assets/img/lauschr_logo.png")}/>}
          fixed
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
        {...rest}
        />
          
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <div className={classes.section} >
              <GridContainer justify="left">
                <GridItem xs={12} sm={12} md={8}>
                  <h2 className={classes.title}>Impressum</h2>
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <p>
                  Kevin Baum<br/>
                  Martin-Luther-Straße 28<br/>
                  66111 Saarbrücken<br/>
                  <br/>
                  Telefon: +49 (0)173 7888-380<br/>
                  E-Mail: hallo@lauschr.de<br/>
                  </p>
                </GridItem>
              </GridContainer>
          </div>
          <div className={classes.section} >
        <GridContainer justify="left">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Datenschutzerklärung</h2>
            <h3 >Allgemeines</h3>
            <p>    
Lauschr ist der Schutz und die Sicherheit Ihrer personenbezogenen Daten sehr wichtig. Aus diesem Grund schützen wir Ihre Daten nach dem aktuellen Stand der Technik und beachten die geltenden Datenschutzvorschriften, insbesondere die Datenschutzgrundverordnung. Wir versuchen auf unserer Webseite so wenig personenbezogene Daten wie möglich zu erheben. Sofern dennoch personenbezogene Daten erhoben werden, weisen wir Sie darauf hin. Ihre Daten geben wir ohne Ihre ausdrückliche Einwilligung nicht an Dritte weiter.
</p>

<h3> Verantwortliche Stelle</h3>

<p>
                  Kevin Baum<br/>
                  Martin-Luther-Straße 28<br/>
                  66111 Saarbrücken<br/>
                  <br/>
                  Telefon: +49 (0)173 7888-380<br/>
                  E-Mail: hallo@lauschr.de<br/>
                  </p>
            
                  <h3>Log-Dateien</h3>

Unsere Webseite bieten wir über Microsoft Azure (Germany West Central (Public),	Frankfurt) an. Diese speichert zur Abwehr und Erkennung von Angriffen sowie zur Fehleranalyse automatisch Informationen in sogenannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:

    Datum, Uhrzeit und Zeitzone der Anfrage an den Server
    vollständige IP-Adresse und Hostname des zugreifenden Rechners / Geräts
    Browsertyp und Browserversion
    verwendetes Betriebssystem
    Referrer URL
    aufgerufene Seite oder Datei mitsamt Größe und Übertrags-Status

Die Datenschutzerklärung von Microsoft finden Sie unter https://privacy.microsoft.com/de-de/privacystatement.
<h3>Kontaktaufnahme</h3>

Sofern Sie unser Kontaktformular verwenden oder auf anderem Wege mit uns Kontakt aufnehmen speichern wir Ihre personenbezogenen Daten zum Zweck der Bearbeitung Ihrer Anfrage. Ihre Daten werden dabei nicht weitergegeben.
          </GridItem>
        </GridContainer>
          </div>
          </div>
          </div>
      </div>
  );
}



import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/kevin.jpg";
import team2 from "assets/img/faces/mikel.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section} ID="team">
      <h2 className={classes.title}>Das Lauschr-Team</h2>
      <div>
        <GridContainer>
          <GridItem xs={18} sm={18} md={6}>
            <Card plain>
              <GridItem xs={18} sm={18} md={6} className={classes.itemGrid}>
                <img src={team1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Kevin Baum
                <br />
                <small className={classes.smallTitle}>Geschäftsführer & Co-Founder</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                 Die gesellschaftlich sinnvolle Anwendung von Technik ist <a href="https://kevinbaum.de">Kevins</a> Leidenschaft. Als Informatiker (M.Sc.) und Philosoph (M.A.) entwickelte er
                  die Vorlesung <a href="https://ethics-for-nerds.de"><i>Ethics for Nerds</i></a> an der Universität des Saarlandes (UdS) und gründete
                  zusammen mit Wissenschaftlern verschiedenster Fachrichtungen die  gemeinnützige Denkfabrik für gute Digitalisierung
                  <a href="https://algoright.de"> <i>Algoright e.V.</i></a>. Derzeit ist er Teil des Projekts  <a href="https://eis.science"> <i>Explainable Artificial Intelligence</i> (EIS)</a>, ist Sachverständiger in der <a href="https://www.landtag-saar.de/landtag/ausschuesse/detail/enqu%C3%AAtekommission-digitalisierung-im-saarland-%E2%80%93-bestandsaufnahme%2C-chancen-und-ma%C3%9Fnahmen/1840Enquetekommission">Enquetekommission <i>Digitalisierung im Saarland – Bestandsaufnahme, Chancen und Maßnahmen</i></a>  sowie <a href="https://www.uni-saarland.de/verwaltung/sicherheitsrelevante-forschung.html"> stellvertrender Vorsitzender der <i>Kommission für die Ethik sicherheitsrelevanter Foschung</i> (KEF)</a> der UdS.
                  Als leidenschaftlicher Podcasthörer kam Kevin auf die Idee zu Lauschr: Wieso kann ich eigentlich all die Artikel, Analysen und Recherchen, die ich
                  lesen will und muss, nicht einfach hören -- und zwar wo und wann ich will? 
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  href="https://twitter.com/ethicist_arbor"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>

                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/kevin-baum-55999580/"
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
                <Button
                  justIcon
                  color="transparent"
                  href="https://kevinbaum.de"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fas fa-globe"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={18} sm={18} md={6}>
            <Card plain>
              <GridItem xs={18} sm={18} md={6} className={classes.itemGrid}>
                <img src={team2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Mikel Schultheis
                <br />
                <small className={classes.smallTitle}>Co-Founder & Entwickler</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                  Mikel's Passion für Softwareentwicklung ist durch das Gaming im Jugendalter entstanden. Dadurch wuchs das Interesse an der Betreibung eigener Gameserver, wodurch er dann auch eigenständig Wissen zu Datenbanksystemen und Netzwerktechniken erlangte. Nach und nach entstand auch Begeisterung an der Softwareentwicklung, was zur Leidenschaft wurde und später auch zu einer Ausbildung als Fachinformatiker für Anwendungsentwicklung führte. In diesem Beruf ist er auch heute noch tätig. Aber auch das Gaming, oder heute vielmehr die Technik dahinter, ist fester Bestandteil seiner Leidenschaft geblieben. Diese Leidenschaft verwirklicht er in einem mit Jugendfreunden gegründeten Verein <a href="https://saar-lan.de/"><i>Saarlan e.V.</i></a>, mit welchem regelmäßig Lanparties mit über 100 Teilnehmern veranstaltet werden.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>

                <Button
                  justIcon
                  color="transparent"
                  className={classes.margin5}
                  href="https://www.linkedin.com/in/mikel-schultheis-25ab76115/"
                >
                <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
